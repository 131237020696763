<script setup>
import { useRouter } from 'vue-router'
import { useConfigStore, useUserStore } from '~/store/index'
// const {route} = useRoute().params //  necessaire (damien)?
// const {locale} = useI18n() //  necessaire (damien)?

await useUserStore().fetchUser()
// const currentUser = useUserStore().currentUser
// if (!currentUser.k) {
//     const router = useRouter()
//     router.replace('/connexion')
// }

await useConfigStore().fetchConfig()

if (useConfigStore().maintenance) {
    const router = useRouter()
    router.replace('/')
}

// const configStore = useConfigStore()
// const {fetchConfig} = configStore;
// await fetchConfig();
</script>

<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>
