import revive_payload_client_cHNRHUXN47 from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@_zkceitmwmoctv6cpdtrm4pft6m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_bEMaxLWtob from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@_zkceitmwmoctv6cpdtrm4pft6m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_h4qOXzGkBh from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@_zkceitmwmoctv6cpdtrm4pft6m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5P3Dtm5sKq from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@_zkceitmwmoctv6cpdtrm4pft6m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_jAGnULUoxS from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@_zkceitmwmoctv6cpdtrm4pft6m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_mwEDhAsl6H from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.1_typescript@5.4.4_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/.nuxt/components.plugin.mjs";
import prefetch_client_Kyko0oZ2jf from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@_zkceitmwmoctv6cpdtrm4pft6m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_41PBeuWQUM from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.1_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_qlmMO91SBX from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@_zkceitmwmoctv6cpdtrm4pft6m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import mergeObjects_JbHzNSpMmN from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/plugins/mergeObjects.ts";
import vuetify_DPVY7aMxlt from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/plugins/vuetify.ts";
import notifier_TfOkCLHRTj from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/packages/datakode/nuxt-datakode-utils/plugins/notifier.ts";
import api_client_t0dWRHSNDZ from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/packages/datakode/nuxt-datakode-utils/plugins/api.client.ts";
import currentPath_yqNfcRq2j9 from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/packages/datakode/nuxt-datakode-utils/plugins/currentPath.ts";
import currentUser_28J1aqNENu from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/packages/datakode/nuxt-datakode-utils/plugins/currentUser.ts";
import translation_NgY8VMsLMG from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/packages/datakode/nuxt-datakode-utils/plugins/translation.ts";
import getColor_Ghj8WPsFjF from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/packages/datakode/nuxt-datakode-utils/plugins/getColor.ts";
import helper_Bdxgb2x3zG from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/packages/datakode/nuxt-datakode-utils/plugins/helper.ts";
import formatDate_za3K2nEobv from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/packages/datakode/nuxt-datakode-utils/plugins/formatDate.ts";
export default [
  revive_payload_client_cHNRHUXN47,
  unhead_bEMaxLWtob,
  router_h4qOXzGkBh,
  payload_client_5P3Dtm5sKq,
  check_outdated_build_client_jAGnULUoxS,
  plugin_vue3_mwEDhAsl6H,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Kyko0oZ2jf,
  i18n_41PBeuWQUM,
  chunk_reload_client_qlmMO91SBX,
  mergeObjects_JbHzNSpMmN,
  vuetify_DPVY7aMxlt,
  notifier_TfOkCLHRTj,
  api_client_t0dWRHSNDZ,
  currentPath_yqNfcRq2j9,
  currentUser_28J1aqNENu,
  translation_NgY8VMsLMG,
  getColor_Ghj8WPsFjF,
  helper_Bdxgb2x3zG,
  formatDate_za3K2nEobv
]