import { default as connexiony5wjWoefeLMeta } from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/connexion.vue?macro=true";
import { default as donnees_45personnellesDrpzgwQefpMeta } from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/donnees-personnelles.vue?macro=true";
import { default as indexKwLiOJ3AEDMeta } from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/pages/index.vue?macro=true";
import { default as mentions_45legalesuJfolhmxAAMeta } from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mentions-legales.vue?macro=true";
import { default as _91token_93TDQjCdLJPfMeta } from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexF5C4QKozgnMeta } from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oubliedPD2QP1FG1Meta } from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie.vue?macro=true";
import { default as indexxAUi4G6lHGMeta } from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/profil/index.vue?macro=true";
import { default as profil3rKftAybMnMeta } from "/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/profil.vue?macro=true";
export default [
  {
    name: connexiony5wjWoefeLMeta?.name ?? "connexion",
    path: connexiony5wjWoefeLMeta?.path ?? "/connexion",
    meta: connexiony5wjWoefeLMeta || {},
    alias: connexiony5wjWoefeLMeta?.alias || [],
    redirect: connexiony5wjWoefeLMeta?.redirect,
    component: () => import("/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/connexion.vue").then(m => m.default || m)
  },
  {
    name: donnees_45personnellesDrpzgwQefpMeta?.name ?? "donnees-personnelles",
    path: donnees_45personnellesDrpzgwQefpMeta?.path ?? "/donnees-personnelles",
    meta: donnees_45personnellesDrpzgwQefpMeta || {},
    alias: donnees_45personnellesDrpzgwQefpMeta?.alias || [],
    redirect: donnees_45personnellesDrpzgwQefpMeta?.redirect,
    component: () => import("/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/donnees-personnelles.vue").then(m => m.default || m)
  },
  {
    name: indexKwLiOJ3AEDMeta?.name ?? "index",
    path: indexKwLiOJ3AEDMeta?.path ?? "/",
    meta: indexKwLiOJ3AEDMeta || {},
    alias: indexKwLiOJ3AEDMeta?.alias || [],
    redirect: indexKwLiOJ3AEDMeta?.redirect,
    component: () => import("/builds/datakode/pildom/APPS/NUXT_PORTAIL/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesuJfolhmxAAMeta?.name ?? "mentions-legales",
    path: mentions_45legalesuJfolhmxAAMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesuJfolhmxAAMeta || {},
    alias: mentions_45legalesuJfolhmxAAMeta?.alias || [],
    redirect: mentions_45legalesuJfolhmxAAMeta?.redirect,
    component: () => import("/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: mot_45de_45passe_45oubliedPD2QP1FG1Meta?.name ?? undefined,
    path: mot_45de_45passe_45oubliedPD2QP1FG1Meta?.path ?? "/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oubliedPD2QP1FG1Meta || {},
    alias: mot_45de_45passe_45oubliedPD2QP1FG1Meta?.alias || [],
    redirect: mot_45de_45passe_45oubliedPD2QP1FG1Meta?.redirect,
    component: () => import("/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie.vue").then(m => m.default || m),
    children: [
  {
    name: _91token_93TDQjCdLJPfMeta?.name ?? "mot-de-passe-oublie-token",
    path: _91token_93TDQjCdLJPfMeta?.path ?? ":token()",
    meta: _91token_93TDQjCdLJPfMeta || {},
    alias: _91token_93TDQjCdLJPfMeta?.alias || [],
    redirect: _91token_93TDQjCdLJPfMeta?.redirect,
    component: () => import("/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue").then(m => m.default || m)
  },
  {
    name: indexF5C4QKozgnMeta?.name ?? "mot-de-passe-oublie",
    path: indexF5C4QKozgnMeta?.path ?? "",
    meta: indexF5C4QKozgnMeta || {},
    alias: indexF5C4QKozgnMeta?.alias || [],
    redirect: indexF5C4QKozgnMeta?.redirect,
    component: () => import("/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profil3rKftAybMnMeta?.name ?? undefined,
    path: profil3rKftAybMnMeta?.path ?? "/profil",
    meta: profil3rKftAybMnMeta || {},
    alias: profil3rKftAybMnMeta?.alias || [],
    redirect: profil3rKftAybMnMeta?.redirect,
    component: () => import("/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/profil.vue").then(m => m.default || m),
    children: [
  {
    name: indexxAUi4G6lHGMeta?.name ?? "profil",
    path: indexxAUi4G6lHGMeta?.path ?? "",
    meta: indexxAUi4G6lHGMeta || {},
    alias: indexxAUi4G6lHGMeta?.alias || [],
    redirect: indexxAUi4G6lHGMeta?.redirect,
    component: () => import("/builds/datakode/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/profil/index.vue").then(m => m.default || m)
  }
]
  }
]